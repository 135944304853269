@import 'style/global.scss';

#about_us {
  h3 {
    //  ;
    font-weight: 900;
    font-size: 34px;
    width: 100%;
    text-align: center;
  }

  #team_section {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @include is_desktop() {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    #team {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include is_desktop() {
        width: 600px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }

  .team_card_info {
    height: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: start;

    @include is_desktop() {
      width: 500px;
    }

    .title {
      font-size: x-large;
    }

    .secondary_title {
      font-weight: 900;
      text-transform: uppercase;
      font-size: 21px;
    }

    p {
      font-size: 21px;
      margin: 12px;
      text-align: center;
    }

    .social {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin: 12px;
        width: 40px;
      }
    }
  }
}

.text {
  text-align: justify !important;
  width: 80%;
}

.scale_anim {
  animation: scale 1s;
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}
