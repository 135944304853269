@import 'style/global.scss';

.team_card {
  position: relative;
  box-sizing: border-box;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  cursor: pointer;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  // transform: scale(1);

  width: 200px;
  height: 200px;

  @include is_phone() {
    animation: unscale 0.5s forwards;
  }

  @include is_desktop() {
    margin: 0 10px;
    animation: close 0.5s forwards;
  }

  img.profil {
    height: 100%;
    border-radius: 50% !important;

    @include is_desktop() {
      border-radius: 0%;
    }
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
  }
}

.opened {
  animation: scale 0.5s forwards;

  @include is_desktop() {
  }
}

@keyframes scale {
  100% {
    // transform: scale(1.1);
    z-index: 2;
  }
}

@keyframes unscale {
  0% {
    // transform: scale(1.1);
    z-index: 2;
  }

  100% {
    // transform: scale(1);
    z-index: 1;
  }
}

@keyframes open {
  0% {
  }

  100% {
    // width: 100%;
  }
}

@keyframes close {
  0% {
    // width: 100%;
  }

  100% {
  }
}
