@import 'style/global.scss';

#info_section {
  margin-top: 3vh;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    margin-top: 9vh;
    font-size: 34px;
    font-weight: 900;
  }

  p {
    text-align: justify;
    font-size: 2.1rem;
  }
}

.values {
  text-align: left;
}
